import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthStatus, useAuth } from '../auth';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import NavBar from './navbar';
import Menu from './menu';

import '../styles/contract.scss';
import { Popover } from 'bootstrap';





const endpoint = process.env.REACT_APP_ENDPOINT;

const Contract = () => {
    const status = AuthStatus();
    const { token } = useAuth();
    const location = useLocation();
    const { register, handleSubmit, watch, setValue } = useForm();


    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [collaborators, setCollaborators] = useState([]);
    const [filteredCollaborators, setFilteredCollaborators] = useState([]);
    const [selectedCollaboratorId, setSelectedCollaboratorId] = useState("");
    const [selectedCollaborator, setSelectedCollaborator] = useState({});
    const [companyAddresses, setCompanyAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');

    const periodo = watch("periodo");

    const daysOfWeek = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    const daysOfMonth = ["Primer día del mes", ...Array.from({ length: 29 }, (_, i) => i + 1), "Último día del mes"];
    
    const getPaymentDays = () => {
        if (periodo === "Semanal") return 1;
        if (periodo === "Decenal") return 3;
        return 2;
    };
    


    // Obtener empresas
    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${endpoint}admin/companies`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCompanies(response.data);
            console.log(response.data);
            if (response.data.length > 0) {
                setSelectedCompany(response.data[0]); // Selecciona la primera empresa
            }
        } catch (error) {
            toast.error('Error al obtener la lista de compañías');
        }
    };

    // Obtener colaboradores
    const fetchCollaborators = async () => {
        try {
            const response = await axios.get(`${endpoint}admin/collaborators`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCollaborators(response.data);
        } catch (error) {
            toast.error('Error al obtener la lista de colaboradores');
        }
    };

    // Filtrar colaboradores cuando cambia la empresa seleccionada
    useEffect(() => {
        if (selectedCompany) {
            const filtered = collaborators.filter(col => col.company_id === selectedCompany.id);
            setFilteredCollaborators(filtered);
            setSelectedCollaborator(""); // Reiniciar selección
            setCompanyAddresses(selectedCompany.addresses || []);
            
            setSelectedAddress(""); // Reiniciar selección de dirección
            if(selectedCompany.benefits){
                setValue('aguinaldo',selectedCompany.benefits.aguinaldo)
                setValue('prima_vacacional',selectedCompany.benefits.prima_vacacional)
            }
            

        }
    }, [selectedCompany, collaborators]);

    useEffect(() => {
      
        
        if (selectedCollaboratorId) {
            const collaborator = collaborators.find(c => c.id == selectedCollaboratorId);
            console.log(collaborators)
            console.log(collaborator)
    
            // Si el colaborador existe, establecer el puesto en el formulario
            if (collaborator) {
                setValue('puesto', collaborator.puesto);
             
            }
        }
    }, [selectedCollaboratorId, collaborators, setValue]);

    useEffect(()=>{
       
        let address = companyAddresses.find(a => a.id == selectedAddress)
        if(address){
            setValue('calle', address.calle)
            setValue('numero_exterior',address.numero_exterior)
            setValue('numero_interior',address.numero_interior)
            setValue('ciudad',address.ciudad)
            setValue('estado', address.estado)
            setValue('colonia', address.colonia)
            setValue('codigo_postal', address.codigo_postal)
        }
        console.log(address)
    },[selectedAddress])

    useEffect(() => {
        fetchCompanies();
        fetchCollaborators();

        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new Popover(popoverTriggerEl, {
                trigger: 'focus' // Se cierra cuando el usuario hace clic fuera
            });
        });
        

    }, [token]);

    return (
        <div className="contract">
            <NavBar auth={status} companies={companies} selected={selectedCompany} set={setSelectedCompany} />
            <div className='contract-row'>
                <Menu />
                <form className='contract-wrapper'>
                    <ToastContainer />
                    <h2>Nuevo documento</h2>
                    <div className='contract-controls'>
                        <button className='btn btn-secondary'>Descartar</button>
                        <button type='submit' className='btn btn-primary'>Guardar cambios</button>
                    </div>

                    <div className="contract-collaborator">
                        <label htmlFor="collaborator-select">Colaborador</label>
                        <select
                            required
                            id="collaborator-select"
                            value={selectedCollaboratorId}
                            onChange={(e) => setSelectedCollaboratorId(e.target.value)}
                        >
                            <option value="">Selecciona una opción</option>
                            {filteredCollaborators.map(collaborator => (
                                <option key={collaborator.id} value={collaborator.id}>
                                    {collaborator.nombre} 
                                </option>
                            ))}
                        </select>
                    </div>

                    <strong>Condiciones de la relación laboral</strong>
                    <div className="contract-info">

                        <div className="c-input">
                            <label htmlFor="puesto">Puesto</label>
                            <input type="text" name='puesto' required
                              
                              {...register("puesto", { required: "Especifica el puesto", value: selectedCollaborator.puesto })}/>
                        </div>
                        <div className="c-input">
                            <label htmlFor="actividad">
                                Actividad a realizar
                                <button type="button" class="btn btn-help" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right"  data-bs-trigger="focus"
                                    data-bs-content="Describe con la mayor precisión posible las actividades que el colaborador va a desempeñar"
                                >
                                 ?
                                </button>

                            </label>
                          
                          
                            <textarea name="actividad" id="" required></textarea>
                        </div>

                        <div className="c-input">
                            <label htmlFor="address-select">Sucursal / Centro de trabajo</label>
                            <select
                                id="address-select"
                                value={selectedAddress}
                                onChange={(e) => setSelectedAddress(e.target.value)}
                                required
                            >
                                <option value="">Selecciona una dirección</option>
                                {companyAddresses.map((address) => (
                                    <option key={address.id} value={address.id}>
                                        {address.calle} {address.numero_exterior}, {address.colonia}, {address.ciudad}, {address.estado}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="c-input">
                            <label htmlFor="">Calle</label>
                            <input type="text" 
                                {...register("calle", { required: "Especifica calle", value: selectedAddress.calle })} 
                            />
                        </div>

                        <div className='c-50'>
                            <div className="c-input">
                                <label htmlFor="">Número exterior</label>
                                <input type="text" 
                                    {...register("numero_exterior", { required: "Especifica", value: selectedAddress.estado })} 
                                />
                            </div>
                            <div className="c-input">
                                <label htmlFor="">Número interior</label>
                                <input type="text" 
                                    {...register("numero_interior", { })} 
                                />
                            </div>

                        </div>

                        <div className='c-33'>
                            <div className="c-input">
                                <label htmlFor="">Codigo postal</label>
                                <input type="text" 
                                    {...register("codigo_postal", { required: "Especifica cp", value: selectedAddress.codigo_postal })} 
                                />
                            </div>
                            <div className="c-input">
                                <label htmlFor="">Colonia</label>
                                <input type="text" 
                                    {...register("colonia", { required: 'Especifica colonia' })} 
                                />
                            </div>

                        </div>
                        

                        <div className="c-input">
                            <label htmlFor="">Estado</label>
                            <input type="text" 
                                {...register("estado", { required: "Especifica estado", value: selectedAddress.estado })} 
                            />
                        </div>

                        <div className="c-input">
                            <label htmlFor="">Ciudad</label>
                            <input type="text" 
                                {...register("ciudad", { required: "Especifica calle", value: selectedAddress.ciudad })} 
                            />
                        </div>

                    </div>

                    <strong>Del salario</strong>
                    <div className="contract-pay">
                        <div className="c-input">
                            <label htmlFor="periodo">Periodo de pago</label>
                            <input 
                                type="text"  
                                list='periodo'
                                {...register("periodo", { required: "Especifica el periodo" })}
                            />
                            <datalist id="periodo">
                            <option value="Semanal" />
                            <option value="Decenal" />
                            <option value="Quincenal" />
                        </datalist>
                        
                        </div>
                        <div className="c-input" >
                            <label htmlFor="periodo">Salario (MXN)</label>
                            <span>
                                <p>$</p>
                                <input
                                    className='salario' 
                                    type="number"  
                                    name='salario'
                                    {...register("salario", { required: "Especifica el salario en moneda nacional" })}
                                />

                            </span>
                            
                        </div>

                        {periodo === "Semanal" ? (
                            <div className='c-input'>
                            <label>Día de pago</label>
                            <select {...register("pagos.0")}> 
                                {daysOfWeek.map((day, index) => (
                                <option key={index} value={day}>{day}</option>
                                ))}
                            </select>
                            </div>
                        ) : periodo ? (
                            <div className='c-input'>
                            {[...Array(getPaymentDays())].map((_, i) => (
                                <div key={i} className='c-input'>
                                <label>Día del {i === 0 ? "primer" : i === 1 ? "segundo" : "tercer"} pago</label>
                                <select {...register(`pagos.${i}`)}>
                                    {daysOfMonth.map((day, index) => (
                                    <option key={index} value={day}>{day}</option>
                                    ))}
                                </select>
                                </div>
                            ))}
                            </div>
                        ) : null}


                        


                    </div>

                    <strong>Vacaciones</strong>
                    <div className="contract-vacations">
                        <VacationYearsInput disabled={false} data={{}}  ></VacationYearsInput>

                        <div className="c-input">
                            <label htmlFor="">Prima vacacional %</label>
                            <input type="text" 
                                {...register("prima_vacacional", { required: "Especifica la prima vacacional"})} 
                            />
                        </div>
                        <div className="c-input">
                            <label htmlFor="">Aguinaldo (dias)</label>
                            <input type="text" 
                                {...register("aguinaldo", { required: "Especifica dias de aguinaldo"})} 
                            />
                        </div>

                        <DynamicInputList data={{}} />

                    </div>
                </form>
            </div>
        </div>
    );

  
};

const VacationYearsInput = ({ data = {}, edit = false, disabled = false }) => {
    const [vacationData, setVacationData] = useState(
        Array.isArray(data.ranges) ? data : { first_year: 12, ranges: [{ step: '', days: '', limit: '' }] }
    );

    const handleInputChange = (field, value) => {
        setVacationData(prev => ({
            ...prev,
            [field]: parseInt(value) || ''
        }));
    };

    const handleRangeChange = (index, field, value) => {
        const updatedRanges = [...vacationData.ranges];
        updatedRanges[index] = {
            ...updatedRanges[index],
            [field]: parseInt(value) || ''
        };

        // Remover líneas vacías que no sean la última
        const filteredRanges = updatedRanges.filter(
            (entry, idx) => idx === updatedRanges.length - 1 || entry.step || entry.days || entry.limit
        );

        setVacationData(prev => ({ ...prev, ranges: filteredRanges }));

        // Agregar nueva entrada vacía si la última está completa
        const lastEntry = filteredRanges[filteredRanges.length - 1];
        if (lastEntry.step && lastEntry.days && lastEntry.limit) {
            setVacationData(prev => ({
                ...prev,
                ranges: [...filteredRanges, { step: '', days: '', limit: '' }]
            }));
        }
    };

    useEffect(() => {
        console.log(data)
        setVacationData(Array.isArray(data.ranges) ? data : { first_year: 12, ranges: [{ step: '', days: '', limit: '' }] });
    }, [data]);

    return (
        <div className="vacation-input-component">

            <div className="c-input">
                 <div className="vacation-input-header">
                    <label htmlFor="">Días de vacaciones al cumplir un año</label>
                </div>
                <input
                    type="number"
                    min="1"
                    value={vacationData.first_year || 12}
                    onChange={(e) => handleInputChange('first_year', e.target.value)}
                    className="form-control"
                    disabled={disabled}
                    placeholder="Días"
                />
                

            </div>
           
            
            <div className="vacation-input-header mt-3">
                <label htmlFor="">Incrementos:</label>
            </div>

            <div className="vacation-input-item">
                <label htmlFor="">Incrementa cada</label>
                <label htmlFor="">Aumento de días</label>
                <label htmlFor="">Hasta los (años)</label>
            </div>

            {vacationData.ranges && vacationData.ranges.map((entry, index) => (
                <div key={index} className="vacation-input-item">

                    <select
                        value={entry.step || ''}
                        onChange={(e) => handleRangeChange(index, 'step', e.target.value)}
                        className="form-control"
                        disabled={disabled}
                    >
                        <option value="">Años</option>
                        {[...Array(10).keys()].map(num => {
                            const value = num + 1;
                            return (
                                <option key={value} value={value}>
                                    {value === 1 ? "Año" : `${value} años`}
                                </option>
                            );
                        })}
                    </select>

                                          
                    <input
                        type="number"
                        min="1"
                        value={entry.days || ''}
                        onChange={(e) => handleRangeChange(index, 'days', e.target.value)}
                        className="form-control"
                        disabled={disabled}
                        placeholder='Días'
                    />
                    <input
                        type="number"
                        min="1"
                        value={entry.limit || ''}
                        onChange={(e) => handleRangeChange(index, 'limit', e.target.value)}
                        className="form-control"
                        disabled={disabled}
                        placeholder="año(s)"
                    />
                </div>
            ))}

            <input
                type="hidden"
                name={edit ? "edit-vacaciones_anuales" : "vacaciones_anuales"}
                value={JSON.stringify(vacationData)}
            />
        </div>
    );
};

const DynamicInputList = ({ data = [], edit = false, disabled = false }) => {
    const [items, setItems] = useState(
        Array.isArray(data) && data.length > 0
            ? data
            : [{ concepto: "", base: "", unidad: "" }]
    );

    const handleInputChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index] = { ...updatedItems[index], [field]: value };

        // Filtrar líneas vacías que no sean la última
        const filteredItems = updatedItems.filter(
            (entry, idx) => idx === updatedItems.length - 1 || entry.concepto || entry.base || entry.unidad
        );

        setItems(filteredItems);

        // Si la última línea está completa, agregar una nueva vacía
        const lastEntry = filteredItems[filteredItems.length - 1];
        if (lastEntry.concepto && lastEntry.base && lastEntry.unidad) {
            setItems([...filteredItems, { concepto: "", base: "", unidad: "" }]);
        }
    };

    useEffect(() => {
        setItems(Array.isArray(data) && data.length > 0 ? data : [{ concepto: "", base: "", unidad: "" }]);
    }, [data]);

    return (
        <div className="dynamic-input-list">
            <div className="dynamic-input-header">
                <label>Concepto</label>
                <label>Base</label>
                <label>Unidad</label>
            </div>

            {items.map((entry, index) => (
                <div key={index} className="dynamic-input-item">
                    <input
                        type="text"
                        value={entry.concepto}
                        onChange={(e) => handleInputChange(index, "concepto", e.target.value)}
                        className="form-control"
                        disabled={disabled}
                        placeholder="Concepto"
                    />
                    <input
                        type="text"
                        value={entry.base}
                        onChange={(e) => handleInputChange(index, "base", e.target.value)}
                        className="form-control"
                        disabled={disabled}
                        placeholder="Base"
                    />
                    <select
                        value={entry.unidad}
                        onChange={(e) => handleInputChange(index, "unidad", e.target.value)}
                        className="form-control"
                        disabled={disabled}
                    >
                        <option value="" disabled>Seleccione</option>
                        <option value="dias">Días</option>
                        <option value="porciento">Porcentaje</option>
                        <option value="pesos">Pesos</option>
                    </select>
                </div>
            ))}

            <input
                type="hidden"
                name={edit ? "edit-dynamic_data" : "dynamic_data"}
                value={JSON.stringify(items.filter(item => item.concepto || item.base || item.unidad))}
            />
        </div>
    );
};



export default Contract;