import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthStatus, useAuth } from '../auth';

import '../styles/documents.scss';

import { useForm } from "react-hook-form";

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";



import NavBar from './navbar';
import Menu   from './menu';

const Documents = ()=>{
     const status = AuthStatus();
    const { token } = useAuth();
    const navigate = useNavigate();


    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [searchTerm, setSearchTerm] = useState('');

    const [selectedType, setSelectedType] = useState("");

    const contractTypes = [
        { value: "obra", label: "Por obra terminada" },
        { value: "determinado", label: "Contrato por tiempo determinado" },
        { value: "indeterminado_capacitacion", label: "Contrato indeterminado con capacitación" },
        { value: "indeterminado_prueba", label: "Contrato indeterminado con prueba" },
        { value: "indeterminado_temporada", label: "Contrato indeterminado por temporada" },
        { value: "indeterminado", label: "Contrato por tiempo indeterminado" }
    ];

    const handleChangeType = (event) => {
       
        setSelectedType(event.target.value);
      
    };

    const handleCreateDocument = () =>{
        if(selectedType == ''){
            toast.error("Selecciona el tipo")
        }else{
            navigate("/admin/contract", { state: {new: selectedType} });
        }
    }


    return(
        <div className="documents">
            <NavBar auth={status} companies={companies} selected={selectedCompany} set={setSelectedCompany}/>
            <div className='documents-row'>
                <Menu/>
                <div className='documents-wrapper'>
                    <ToastContainer />
                    <h2>Documentos</h2>

                    <div className="documents-controls">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar documento..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        <span>
                        
                   
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createModal">
                            Agregar documento
                        </button>
                          
                         

                        </span>
                        
                    </div>

                    <div style={{ height: '80vh', width: '100%' }} className='documents-table'>
                        {/* <DataGrid
                            rows={filteredCollaborators}
                            columns={columns}
                            sx={{ borderColor: 'white' }}
                            pageSize={5}
                            onRowDoubleClick={()=>{document.getElementById('view').click(); setForm();}}
                            onRowClick={(row) => {setSelectedCollaborator(row.row); setForm(); document.getElementById('view').click() }}
                        /> */}
                    </div>
                    
                </div>
            </div>

    
            <div class="modal fade" id="createModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Crear documento</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label htmlFor="">Tipo de documento</label>
                        <select 
                            id="contract-type"
                            className="form-control"
                            value={selectedType}
                            onChange={handleChangeType}
                        >
                            <option value="">Elige una opción</option>
                            {contractTypes.map((type) => (
                                <option key={type.value} value={type.value}>
                                    {type.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div class="modal-footer">
                       
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={handleCreateDocument}>Continuar</button>
                    </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Documents;